// HomePage.js
import React, { useEffect, useState } from 'react';
import WorkHistory from './WorkHistory';
import EducationSection from './EducationSection';
import MyMusic from './MyMusic';
import { Footer } from 'flowbite-react';
import '../css/HomePage.css';
import { Link } from 'react-router-dom';
import config from '../config';

const HomePage = () => {
  const [favoriteReads, setFavoriteReads] = useState({ fiction: [], nonFiction: [] });
  useEffect(() => {
    const fetchReads = async () => {
      try {

        // Fetch data from /api/books
        const booksResponse = await fetch(`${config.apiUrl}/api/books`);
        const booksData = await booksResponse.json();
        setFavoriteReads(booksData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchReads();
  }, []);
  return (
    <div className="homepage-container">
      
      <div className="landing-homepage">
        <div className="top-line"><strong>JAKE BALDWIN</strong></div>
        <div className="second-line"><strong>SOFTWARE ENGINEER</strong></div>
        <img src='iceland.jpg' className="mobile-img" />
        <div className="left-lower-tag">
          <p>An aspiring polymath. Exploring the world through code, music, and constant curiosity.</p>
          <Link to="/contact">
            <button className="blog-editor-button">Get in Touch!</button>
          </Link>  
        </div>
        <div className="right-lower-tag">
          <p><i>He's quite the catch</i> - Jake's Mom</p>
        </div>
        <img src='iceland.jpg' className='landing-main-image'/>
      </div>
      
      
      <div className='content-container left-container'>
        <WorkHistory />
      </div>
      <div className='content-container left-container'>
        <EducationSection />
      </div>
      <div className='content-container left-container'>
        <h2> What I'm Listening To </h2>
        <p>this playlist is exclusively my favorite songs of all time</p>
        <iframe 
          style={{ borderRadius: '12px' }}
          src="https://open.spotify.com/embed/playlist/5YllyDA60lEAw2LpNDymsn?utm_source=generator" 
          width="100%" 
          height="352" 
          frameBorder="0" 
          allowfullscreen="" 
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
          loading="lazy">  
        </iframe>
      </div>

      <div className="content-container left-container">
        <h2>My Music</h2>
        <p>and I haven't ever had a lesson! (try clicking these)</p>
        <MyMusic />
      </div>

      <div className='content-container left-container'>
        Also, if you are a potential employer checkout  <Link to="https://jakebaldwin.org/blog/2024/1/24/7-reasons-why-you-should-hire-me">
           7 Reasons Why You Should Hire Me
        </Link>
      </div>
      
      <Footer container>
      <div className="w-full text-center">
        <div className="w-full justify-between sm:flex sm:items-center sm:justify-between">
          <Footer.Brand
            href="/"
            src="logo.png"
            alt="jake baldwin"
            name="Programming"
          />
          <Footer.LinkGroup>
            <Link href="/" className="footer-link">Home</Link>
            <Link href="/projects" className="footer-link">Projects</Link>
            <Link href="/blog" className="footer-link">Blog</Link>
            <Link href="/contact" className="footer-link">Get In Touch</Link>
          </Footer.LinkGroup>
        </div>
        <Footer.Divider />
        <Footer.Copyright href="#" by="jake baldwin" year={2024} />
      </div>
    </Footer>
  
    </div>
  );
};

export default HomePage;
