import React, { useState, useEffect } from 'react';
import '../../css/Editor.css';
import config from '../../config';

const WorkExperienceEditor = () => {
  const [workExperiences, setWorkExperiences] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const EXPERIENCES_PER_PAGE = 10;
  const [visibleCount, setVisibleCount] = useState(EXPERIENCES_PER_PAGE);

  // Fetch work experiences from the backend
  useEffect(() => {
    const fetchWorkExperiences = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/api/workexperience`, {
          method: 'GET',
        });

        if (response.ok) {
          const data = await response.json();
          // Sort by `sortStartDate` descending
          const sortedData = data.sort((a, b) => new Date(b.sortStartDate) - new Date(a.sortStartDate));
          setWorkExperiences(sortedData);
        } else {
          console.error('Failed to fetch work experiences.');
        }
      } catch (error) {
        console.error('Error fetching work experiences:', error);
      }
    };

    fetchWorkExperiences();
  }, []);

  // Show more work experiences
  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + EXPERIENCES_PER_PAGE);
  };

  // Open modal for editing a work experience
  const handleEdit = (experience) => {
    setSelectedExperience(experience);
    setIsAdding(false);
    setShowModal(true);
  };

  // Open modal for adding a new work experience
  const handleAdd = () => {
    setSelectedExperience({
      title: '',
      description: '',
      startDate: '',
      endDate: '',
      sortStartDate: '',
      logo: '', // Add logo field
    });
    setIsAdding(true);
    setShowModal(true);
  };

  // Save a work experience (create or update)
  const handleSave = async () => {
    try {
      const url = isAdding
        ? `${config.apiUrl}/api/workexperience/create`
        : `${config.apiUrl}/api/workexperience/update`;
      const method = isAdding ? 'POST' : 'PUT';

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jakebaldwinorgaccessToken')}`,
        },
        body: JSON.stringify(selectedExperience),
      });

      if (response.ok) {
        if (isAdding) {
          const newExperience = await response.json();
          setWorkExperiences((prevExperiences) => [newExperience, ...prevExperiences]);
        } else {
          setWorkExperiences((prevExperiences) =>
            prevExperiences.map((exp) =>
              exp.title === selectedExperience.title ? selectedExperience : exp
            )
          );
        }

        setShowModal(false);
        setSelectedExperience(null);
      } else {
        alert('Failed to save the work experience. Please try again.');
      }
    } catch (error) {
      console.error('Error saving work experience:', error);
    }
  };

  // Delete a work experience
  const handleDelete = async (title) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this work experience?');

    if (!isConfirmed) {
      return; // Exit the function if the user cancels
    }

    try {
      const response = await fetch(`${config.apiUrl}/api/workexperience/delete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jakebaldwinorgaccessToken')}`,
        },
        body: JSON.stringify({ title }),
      });

      if (response.ok) {
        setWorkExperiences((prevExperiences) =>
          prevExperiences.filter((exp) => exp.title !== title)
        );
        alert('Work experience deleted successfully.');
      } else {
        alert('Failed to delete the work experience. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting work experience:', error);
    }
  };

  // Close modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedExperience(null);
  };

  return (
    <div className="work-experience-editor">
      <h3>Work Experience Editor</h3>
      <button className="add-btn" onClick={handleAdd}>
        Add New Experience
      </button>
      <ul className="editor-item-list">
        {workExperiences.slice(0, visibleCount).map((experience) => (
          <li key={experience.title} className="editor-item">
            <h4>{experience.title}</h4>
            <button onClick={() => handleEdit(experience)}>Edit</button>
            <button
              className="delete-btn"
              onClick={() => handleDelete(experience.title)}
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
      {visibleCount < workExperiences.length && (
        <button className="load-more-btn" onClick={handleShowMore}>
          Load More
        </button>
      )}

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>{isAdding ? 'Add Work Experience' : 'Edit Work Experience'}</h3>
            <label>Title</label>
            <input
              type="text"
              value={selectedExperience?.title || ''}
              onChange={(e) =>
                setSelectedExperience({
                  ...selectedExperience,
                  title: e.target.value,
                })
              }
            />
            <label>Description</label>
            <textarea
              value={selectedExperience?.description || ''}
              onChange={(e) =>
                setSelectedExperience({
                  ...selectedExperience,
                  description: e.target.value,
                })
              }
            ></textarea>
            <label>Start Date</label>
            <input
              type="text"
              value={selectedExperience?.startDate || ''}
              onChange={(e) =>
                setSelectedExperience({
                  ...selectedExperience,
                  startDate: e.target.value,
                })
              }
            />
            <label>End Date</label>
            <input
              type="text"
              value={selectedExperience?.endDate || ''}
              onChange={(e) =>
                setSelectedExperience({
                  ...selectedExperience,
                  endDate: e.target.value,
                })
              }
            />
            <label>Sort Start Date</label>
            <input
              type="date"
              value={selectedExperience?.sortStartDate || ''}
              onChange={(e) =>
                setSelectedExperience({
                  ...selectedExperience,
                  sortStartDate: e.target.value,
                })
              }
            />
            <label>Logo URL</label>
            <input
              type="text"
              value={selectedExperience?.logo || ''}
              onChange={(e) =>
                setSelectedExperience({
                  ...selectedExperience,
                  logo: e.target.value,
                })
              }
            />
            <div className="editor-buttons">
              <button className="add-btn" onClick={handleSave}>
                Save
              </button>
              <button className="cancel-btn" onClick={handleCloseModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkExperienceEditor;