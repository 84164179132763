import React, { useState, useEffect } from 'react';
import '../../css/Editor.css';
import config from '../../config';

const BlogEditor = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentBlog, setCurrentBlog] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [visibleCount, setVisibleCount] = useState(10);
  const BLOGS_PER_PAGE = 10;

  const fetchBlogPreviews = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/api/blog-previews`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jakebaldwinorgaccessToken')}`,
        },
      });

      const previews = await response.json();
      const sortedBlogs = previews.sort((a, b) => b.timestamp - a.timestamp);
      setBlogs(sortedBlogs);
    } catch (error) {
      console.error('Error fetching blog previews:', error);
    }
  };

  useEffect(() => {
    fetchBlogPreviews();
  }, []);

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + BLOGS_PER_PAGE);
  };

  const handleEdit = (blog) => {
    setCurrentBlog(blog);
    setShowModal(true);
  };

  const handleSave = async () => {
    const formData = new FormData();
    formData.append('title', currentBlog.title);
    formData.append('preview', currentBlog.preview);
    formData.append('thumbnailUrl', currentBlog.thumbnailUrl || '');
    formData.append('postID', currentBlog.postID || '');
    formData.append('timestamp', currentBlog.timestamp || Date.now().toString());
    if (currentBlog.markdownFile) {
      formData.append('markdownFile', currentBlog.markdownFile);
    }

    const endpoint = currentBlog.postID ? '/api/blog/update' : '/api/blog/create';

    try {
      const response = await fetch(`${config.apiUrl}${endpoint}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jakebaldwinorgaccessToken')}`,
        },
        body: formData,
      });

      if (response.ok) {
        alert(`Blog ${currentBlog.postID ? 'updated' : 'created'} successfully!`);
        setShowModal(false);
        setCurrentBlog(null);
        await fetchBlogPreviews();
      } else {
        alert(`Failed to ${currentBlog.postID ? 'update' : 'create'} blog. Please try again.`);
      }
    } catch (error) {
      console.error(`Error ${currentBlog.postID ? 'updating' : 'creating'} blog:`, error);
    }
  };

  const handleDelete = async (postID, timestamp) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this blog post?');

    if (!isConfirmed) {
      return;
    }

    try {
      const response = await fetch(`${config.apiUrl}/api/blog/delete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jakebaldwinorgaccessToken')}`,
        },
        body: JSON.stringify({ postID, timestamp }),
      });

      if (response.ok) {
        setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog.postID !== postID));
        alert('Blog post deleted successfully.');
      } else {
        alert('Failed to delete the blog post. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting blog:', error);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setCurrentBlog(null);
  };

  const handleCreateNew = () => {
    setCurrentBlog({ title: '', preview: '', thumbnailUrl: '', markdownFile: null });
    setShowModal(true);
  };

  return (
    <div className="blog-editor">
      <h3>Blog Editor</h3>
      <button className="add-btn" onClick={handleCreateNew}>
        Create New Blog
      </button>
      <ul className="editor-item-list">
        {blogs.slice(0, visibleCount).map((blog) => (
          <li key={blog.postID} className="editor-item">
            <h4>{blog.title}</h4>
            <button onClick={() => handleEdit(blog)}>Edit</button>
            <button onClick={() => handleDelete(blog.postID, blog.timestamp)}>Delete</button>
          </li>
        ))}
      </ul>
      {visibleCount < blogs.length && (
        <button className="load-more-btn" onClick={handleShowMore}>
          Load More
        </button>
      )}

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>{currentBlog?.postID ? 'Edit Blog' : 'Create Blog'}</h3>
            <label>Title</label>
            <input
              type="text"
              value={currentBlog?.title || ''}
              onChange={(e) => setCurrentBlog({ ...currentBlog, title: e.target.value })}
            />
            <label>Preview</label>
            <textarea
              value={currentBlog?.preview || ''}
              onChange={(e) => setCurrentBlog({ ...currentBlog, preview: e.target.value })}
            ></textarea>
            <label>Thumbnail URL</label>
            <input
              type="text"
              value={currentBlog?.thumbnailUrl || ''}
              onChange={(e) => setCurrentBlog({ ...currentBlog, thumbnailUrl: e.target.value })}
            />
            <label>Markdown File</label>
            <input
              type="file"
              onChange={(e) =>
                setCurrentBlog({ ...currentBlog, markdownFile: e.target.files[0] })
              }
            />
            <div className="editor-buttons">
              <button className="add-btn" onClick={handleSave}>
                Save
              </button>
              <button className="cancel-btn" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogEditor;