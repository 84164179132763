import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/BlogPage.css';
import config from '../config';

const BlogPage = () => {
  const [metadata, setMetadata] = useState([]);
  const [page, setPage] = useState(1);

  // Fetch all blog metadata for the list of titles
  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/api/blog-previews`);
        const articles = await response.json();

        const sortedMetadata = articles.sort((a, b) => b.timestamp - a.timestamp);
        setMetadata(sortedMetadata);
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    };

    fetchMetadata();
  }, [page]);

  return (
    <div className="blog-container">
      <div className="page-header">
        <h1 className="page-title">Essays / Thoughts / Blog</h1>
        <p className="page-description">My various writing</p>
      </div>

      {/* Blog Titles List */}
      <div className="blog-list-container">
        <ul className="blog-titles-list">
          {metadata.map((post) => (
            <li key={post.postID} className="blog-title-item">
              <Link
                to={`/blog/${post.postID.split(':').join('/')}`}
                className="blog-title-link"
              >
                {post.title}, {post.postID.slice(0,4)}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BlogPage;