import React, { useState, useEffect } from 'react';
import { Timeline } from 'flowbite-react';
import config from '../config';

const WorkHistory = () => {
  const [workExperiences, setWorkExperiences] = useState([]);

  // Fetch work experiences from the backend
  useEffect(() => {
    const fetchWorkExperiences = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/api/workexperience`, {
          method: 'GET',
        });

        if (response.ok) {
          const data = await response.json();
          // Sort by sortStartDate in descending order (most recent first)
          const sortedData = data.sort((a, b) => new Date(b.sortStartDate) - new Date(a.sortStartDate));
          setWorkExperiences(sortedData);
        } else {
          console.error('Failed to fetch work experiences.');
        }
      } catch (error) {
        console.error('Error fetching work experiences:', error);
      }
    };

    fetchWorkExperiences();
  }, []);

  const LogoIcon = ({ logoSrc, altText }) => (
    <img
      src={logoSrc || 'https://via.placeholder.com/24'} // Default placeholder if no logo is provided
      alt={altText}
      style={{ width: '24px', height: '24px', borderRadius: '50%' }}
    />
  );

  return (
    <div>
      <h2>Experience</h2>
      <Timeline>
        {workExperiences.map((experience, index) => (
          <Timeline.Item key={index}>
            <Timeline.Point
              icon={() => (
                <LogoIcon
                  logoSrc={experience.logo} // Logo URL from the database
                  altText={`${experience.title} Logo`}
                />
              )}
            />
            <Timeline.Content>
              <Timeline.Time>
                {experience.startDate} - {experience.endDate}
              </Timeline.Time>
              <Timeline.Title>{experience.title}</Timeline.Title>
              <Timeline.Body>{experience.description}</Timeline.Body>
            </Timeline.Content>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  );
};

export default WorkHistory;