import React, { useState, useEffect } from 'react';
import '../../css/Editor.css';
import config from '../../config';

const BookEditor = () => {
  const [books, setBooks] = useState([]);
  const [visibleCount, setVisibleCount] = useState(10);
  const [selectedBook, setSelectedBook] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const BOOKS_PER_PAGE = 10;

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/api/books`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jakebaldwinorgaccessToken')}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setBooks(data);
        } else {
          console.error('Failed to fetch books.');
        }
      } catch (error) {
        console.error('Error fetching books:', error);
      }
    };

    fetchBooks();
  }, []);

  const handleShowMore = () => setVisibleCount((prev) => prev + BOOKS_PER_PAGE);

  const handleEdit = (book) => {
    setSelectedBook(book);
    setIsAdding(false);
    setShowModal(true);
  };

  const handleAdd = () => {
    setSelectedBook({ title: '', author: '', ISBN: '', rating: 0, image: null });
    setIsAdding(true);
    setShowModal(true);
  };

const handleSave = async (e) => {
  e.preventDefault();

  try {
    const formData = new FormData();

    // Validate and clamp the rating value to ensure it is a valid number within the range [0, 5]
    const clampedRating = Math.max(0, Math.min(5, parseFloat(selectedBook.rating) || 0));

    formData.append('title', selectedBook.title);
    formData.append('author', selectedBook.author);
    formData.append('ISBN', selectedBook.ISBN);
    formData.append('rating', clampedRating); // Pass the clamped rating
    if (selectedBook.imageFile) {
      formData.append('image', selectedBook.imageFile);
    }

    const url = isAdding
      ? `${config.apiUrl}/api/books/add`
      : `${config.apiUrl}/api/books/update/${selectedBook.ISBN}`;
    const method = isAdding ? 'POST' : 'PUT';

    const response = await fetch(url, {
      method,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jakebaldwinorgaccessToken')}`,
      },
      body: formData,
    });

    if (response.ok) {
      const updatedBook = await response.json();

      setBooks((prevBooks) =>
        isAdding
          ? [updatedBook, ...prevBooks]
          : prevBooks.map((book) =>
              book.ISBN === updatedBook.ISBN ? updatedBook : book
            )
      );

      setShowModal(false);
      setSelectedBook(null);
    } else {
      alert('Failed to save the book. Please try again.');
    }
  } catch (error) {
    console.error('Error saving book:', error);
  }
};

  const handleDelete = async (ISBN) => {
    if (!window.confirm('Are you sure you want to delete this book?')) return;

    try {
      const response = await fetch(`${config.apiUrl}/api/books/delete/${ISBN}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jakebaldwinorgaccessToken')}`,
        },
      });

      if (response.ok) {
        setBooks((prevBooks) => prevBooks.filter((book) => book.ISBN !== ISBN));
        alert('Book deleted successfully.');
      } else {
        alert('Failed to delete the book. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting book:', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedBook(null);
  };

  return (
    <div className="book-editor">
      <h3>Book Editor</h3>
      <button className="add-btn" onClick={handleAdd}>
        Add New Book
      </button>
      <ul className="editor-item-list">
        {books.slice(0, visibleCount).map((book) => (
          <li key={book.ISBN} className="editor-item">
            <h4>{book.title}</h4>
            <button onClick={() => handleEdit(book)}>Edit</button>
            <button className="delete-btn" onClick={() => handleDelete(book.ISBN)}>
              Delete
            </button>
          </li>
        ))}
      </ul>
      {visibleCount < books.length && (
        <button className="load-more-btn" onClick={handleShowMore}>
          Load More
        </button>
      )}

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>{isAdding ? 'Add New Book' : 'Edit Book'}</h3>
            <form>
              <label>
                Title:
                <input
                  type="text"
                  value={selectedBook?.title || ''}
                  onChange={(e) =>
                    setSelectedBook((prev) => ({ ...prev, title: e.target.value }))
                  }
                />
              </label>
              <label>
                Author:
                <input
                  type="text"
                  value={selectedBook?.author || ''}
                  onChange={(e) =>
                    setSelectedBook((prev) => ({ ...prev, author: e.target.value }))
                  }
                />
              </label>
              <label>
                ISBN:
                <input
                  type="text"
                  value={selectedBook?.ISBN || ''}
                  onChange={(e) =>
                    setSelectedBook((prev) => ({ ...prev, ISBN: e.target.value }))
                  }
                  disabled={!isAdding}
                />
              </label>
              <label>
                Rating:
                <input
                  type="number"
                  min="0"
                  max="5"
                  step="0.1"
                  value={selectedBook?.rating || ''}
                  onChange={(e) =>
                    setSelectedBook((prev) => ({
                      ...prev,
                      rating: parseFloat(e.target.value),
                    }))
                  }
                />
              </label>
              <label>
                Image:
                <input
                  type="file"
                  onChange={(e) =>
                    setSelectedBook((prev) => ({
                      ...prev,
                      imageFile: e.target.files[0],
                    }))
                  }
                />
              </label>
              <div className="editor-buttons">
                <button className="add-btn" onClick={handleSave}>
                  Save
                </button>
                <button className="cancel-btn" onClick={handleCloseModal}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookEditor;