// ProjectsPage.js
import React from 'react';
import '../css/ProjectsPage.css';
import ProjectModal from './ProjectModal';
import Filters from './Filters';
import { useState, useEffect } from 'react';
import config from '../config';

// delete if backend will return sorted by date
const ProjectsPage = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Fetch all saved projects from the backend
    fetch(`${config.apiUrl}/api/projects`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jakebaldwinorgaccessToken')}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setProjects(data);
        
        // Sort the projects by date
        const sortedProjects = [...data].sort((a, b) => {
          if (a.date.toLowerCase() === 'present') {
            return -1;
          } else if (b.date.toLowerCase() === 'present') {
            return 1;
          } else {
            const dateA = new Date(a.date + ' 1');
            const dateB = new Date(b.date + ' 1');
            return dateB - dateA;
          }
        });
        setProjects(sortedProjects);
      })
      .catch(error => {
        console.error('Error fetching projects:', error);
      });
  }, []);


  const openModal = (project) => {
    setSelectedProject(project);
  };

  const closeModal = () => {
    setSelectedProject(null);
  };

  const handleTagSelect = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  // Filter projects based on selected tags
  const filteredProjects =
    selectedTags.length === 0
      ? projects
      : projects.filter((project) => project.tags.some((tag) => selectedTags.includes(tag)));

  return (
    <div className='projects-page-total-container'>
      <div className="page-header">
        <h1 className="page-title">My Projects</h1>
        <p className="page-description">Explore some of my featured projects below.</p>
        <Filters projects={projects} selectedTags={selectedTags} onSelectTag={handleTagSelect} />
      </div>
      <div className="projects-page-container">
        <div className="left-projects-pane">
          {filteredProjects.map((project, index) =>
            index % 2 === 0 ? (
              <div className="project-bubble" key={project.projectID} onClick={() => openModal(project)}>
                <img src={project.imageUrl} alt={project.title} />
                <h3>{project.title}</h3>
                <p><strong>{project.date}</strong></p>
                <p>{project.description}</p>
              </div>
            ) : null
          )}
        </div>
        <div className="right-projects-pane">
          {filteredProjects.map((project, index) =>
            index % 2 === 1 ? (
              <div className="project-bubble" key={project.projectID} onClick={() => openModal(project)}>
                <img src={project.imageUrl} alt={project.title} />
                <h3>{project.title}</h3>
                <p><strong>{project.date}</strong></p>
                <p>{project.description}</p>
              </div>
            ) : null
          )}
        </div>
        {selectedProject && <ProjectModal project={selectedProject} onClose={closeModal} />}
      </div>
    </div>
  );
};

export default ProjectsPage;
