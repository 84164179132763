import React, { useState, useEffect } from 'react';
import '../css/BooksPage.css';
import config from '../config';

const BooksPage = () => {
  const [books, setBooks] = useState([]);

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/api/books`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jakebaldwinorgaccessToken')}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setBooks(data); // Data already includes imageUrl for books
        } else {
          console.error('Failed to fetch books.');
        }
      } catch (error) {
        console.error('Error fetching books:', error);
      }
    };

    fetchBooks();
  }, []);

  return (
    <div className="books-page-container">
      <div className="page-header">
        <h1 className="page-title">Books I've Read</h1>
        <p className="page-description">A collection of books I've enjoyed over the years.</p>
      </div>
      <div className="books-grid">
        {books.map((book) => (
          <div className="book-item" key={book.ISBN}>
            <img
              src={book.imageUrl || 'https://via.placeholder.com/128x192?text=No+Image'}
              alt={book.title || 'Book Cover'}
              className="book-image"
            />
            <h3 className="book-title">{book.title}</h3>
            <p className="book-author">{book.author}</p>
            {book.rating && <RatingStars rating={book.rating} />}
          </div>
        ))}
      </div>
    </div>
  );
};

// Component to display star rating
const RatingStars = ({ rating }) => {
  const stars = Array.from({ length: rating }, (_, index) => (
    <span key={index} className='star-filled'>
      ★
    </span>
  ));
  return <div className="rating-stars">{stars}</div>;
};

export default BooksPage;