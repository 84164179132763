import React, { useState } from 'react';
import BlogEditor from './editors/BlogEditor';
import BookEditor from './editors/BookEditor';
import ProjectEditor from './editors/ProjectEditor';
import WorkExperienceEditor from './editors/WorkExperienceEditor';
import config from '../config';
import '../css/Dashboard.css';

const Dashboard = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('jakebaldwinorgaccessToken'));
  const [credentials, setCredentials] = useState({ username: '', password: '' });

  const handleLogin = async () => {
    try {
      
      const response = await fetch(`${config.apiUrl}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });

      if (!response.ok) {
        throw new Error('Invalid credentials. Please try again.');
      }

      const { token } = await response.json();
      localStorage.setItem('jakebaldwinorgaccessToken', token); // Save the token for authenticated requests
      setIsLoggedIn(true);
    } catch (error) {
      alert(error.message);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('jakebaldwinorgaccessToken'); // Clear the token
    setIsLoggedIn(false); // Reset the login state
  };

  if (!isLoggedIn) {
    return (
      <div className="login-container">
        <h2>Login</h2>
        <input
          type="text"
          placeholder="Username"
          value={credentials.username}
          onChange={(e) => setCredentials({ ...credentials, username: e.target.value })}
        />
        <input
          type="password"
          placeholder="Password"
          value={credentials.password}
          onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
        />
        <button onClick={handleLogin}>Login</button>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h2>Admin Dashboard</h2>
        <button onClick={handleLogout} className="logout-btn">
          Logout
        </button>
      </div>
      <div className="editors-container">
        <BlogEditor />
        <BookEditor />
        <ProjectEditor />
        <WorkExperienceEditor />
      </div>
    </div>
  );
};

export default Dashboard;