import React, { useState, useEffect } from 'react';
import '../../css/Editor.css';
import config from '../../config';

const ProjectEditor = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const PROJECTS_PER_PAGE = 10;
  const [visibleCount, setVisibleCount] = useState(PROJECTS_PER_PAGE);

  // Fetch projects from the backend
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/api/projects`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jakebaldwinorgaccessToken')}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setProjects(data);
        } else {
          console.error('Failed to fetch projects.');
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, []);

  // Show more projects
  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + PROJECTS_PER_PAGE);
  };

  // Open modal for editing a project
  const handleEdit = (project) => {
    setSelectedProject(project);
    setIsAdding(false);
    setShowModal(true);
  };

  // Open modal for adding a new project
  const handleAdd = () => {
    setSelectedProject({
      projectID: '',
      title: '',
      description: '',
      articleLink: '',
      projectLink: '',
      date: '',
      githubLink: '',
      imageUrl: '',
      tags: '',
    });
    setIsAdding(true);
    setShowModal(true);
  };

  // Save a project (create or update)
  const handleSave = async () => {
    try {
      const updatedProject = isAdding
        ? { ...selectedProject, projectID: selectedProject.title.trim() } // Set projectID to title
        : selectedProject;

      const url = isAdding
        ? `${config.apiUrl}/api/projects/add`
        : `${config.apiUrl}/api/projects/update/${updatedProject.projectID}`;

      const response = await fetch(url, {
        method: isAdding ? 'POST' : 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jakebaldwinorgaccessToken')}`,
        },
        body: JSON.stringify(updatedProject),
      });

      if (response.ok) {
        if (isAdding) {
          const newProject = await response.json();
          setProjects((prevProjects) => [newProject, ...prevProjects]);
        } else {
          setProjects((prevProjects) =>
            prevProjects.map((project) =>
              project.projectID === updatedProject.projectID ? updatedProject : project
            )
          );
        }

        setShowModal(false);
        setSelectedProject(null);
      } else {
        alert('Failed to save the project. Please try again.');
      }
    } catch (error) {
      console.error('Error saving project:', error);
    }
  };

  // Delete a project
  const handleDelete = async (projectID) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this project?');

    if (!isConfirmed) {
      return; // Exit the function if the user cancels
    }

    try {
      const response = await fetch(`${config.apiUrl}/api/projects/delete/${projectID}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jakebaldwinorgaccessToken')}`,
        },
      });

      if (response.ok) {
        setProjects((prevProjects) => prevProjects.filter((project) => project.projectID !== projectID));
        alert('Project deleted successfully.');
      } else {
        alert('Failed to delete the project. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  // Close modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProject(null);
  };

  return (
    <div className="project-editor">
      <h3>Project Editor</h3>
      <button className="add-btn" onClick={handleAdd}>
        Add New Project
      </button>
      <ul className="editor-item-list">
        {projects.slice(0, visibleCount).map((project) => (
          <li key={project.projectID} className="editor-item">
            <h4>{project.title}</h4>
            <button onClick={() => handleEdit(project)}>Edit</button>
            <button className="delete-btn" onClick={() => handleDelete(project.projectID)}>
              Delete
            </button>
          </li>
        ))}
      </ul>
      {visibleCount < projects.length && (
        <button className="load-more-btn" onClick={handleShowMore}>
          Load More
        </button>
      )}

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>{isAdding ? 'Add New Project' : 'Edit Project'}</h3>
            <label>Title</label>
            <input
              type="text"
              value={selectedProject?.title || ''}
              onChange={(e) =>
                setSelectedProject({ ...selectedProject, title: e.target.value })
              }
            />
            <label>Description</label>
            <textarea
              value={selectedProject?.description || ''}
              onChange={(e) =>
                setSelectedProject({ ...selectedProject, description: e.target.value })
              }
            ></textarea>
            <label>Article Link</label>
            <input
              type="text"
              value={selectedProject?.articleLink || ''}
              onChange={(e) =>
                setSelectedProject({ ...selectedProject, articleLink: e.target.value })
              }
            />
            <label>Project Link</label>
            <input
              type="text"
              value={selectedProject?.projectLink || ''}
              onChange={(e) =>
                setSelectedProject({ ...selectedProject, projectLink: e.target.value })
              }
            />
            <label>Date</label>
            <input
              type="text"
              value={selectedProject?.date || ''}
              onChange={(e) =>
                setSelectedProject({ ...selectedProject, date: e.target.value })
              }
            />
            <label>GitHub Link</label>
            <input
              type="text"
              value={selectedProject?.githubLink || ''}
              onChange={(e) =>
                setSelectedProject({ ...selectedProject, githubLink: e.target.value })
              }
            />
            <label>Image URL</label>
            <input
              type="text"
              value={selectedProject?.imageUrl || ''}
              onChange={(e) =>
                setSelectedProject({ ...selectedProject, imageUrl: e.target.value })
              }
            />
            <label>Tags (comma-separated)</label>
            <input
              type="text"
              value={selectedProject?.tags || ''}
              onChange={(e) =>
                setSelectedProject({ ...selectedProject, tags: e.target.value })
              }
            />
            <div className="editor-buttons">
              <button className="add-btn" onClick={handleSave}>
                Save
              </button>
              <button className="cancel-btn" onClick={handleCloseModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectEditor;